<template>
  <div class="login">
    <v-container>
      <v-row class="text-center primary--text">
        <v-col cols="12">
          <p class="text-h5 fonte1" style="font-family: Kelly Slab !important">
            <strong style="font-family: Kelly Slab !important"
              >Aproveite a Oportunidade</strong
            >
            de negociar seu débito.
          </p>

          <p class="text-h5" style="font-family: Kelly Slab !important">
            Informe seu CPF ou CNPJ abaixo para ver a oferta
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="text-center"></v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Entre com o CPF/CNPJ"
                  v-model="login"
                  prepend-icon="mdi-account"
                  type="text"
                  @keyup.enter="enviar"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" block @click="enviar">Ver Oferta</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { api } from "@/conf/api";

export default {
  name: "LoginSite",
  data() {
    return {
      login: "",
    };
  },
  async mounted() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
    this.$store.commit("setDialogo", false);
    this.$store.commit("setApareceBanner", false);
    //this.$router.push({ name: "DividasPage" });
    await this.enviar2();
  },
  methods: {
    coloca(a, c) {
      this.$store.dispatch("insereDividas2", a);
      this.$store.dispatch("insereCPF", c);
    },
    async enviar() {
      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");

      // cpf = "00000048712";

      if (!cpf) {
        this.$store.commit("showMessage", {
          text: "Coloque um CPF ou CNPJ para ver as Ofertas",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }

      await api.post("Logs/inserecpflog",{cpf})

      let res = await api.get("dividas/" + cpf);

      if (res.data.dados.length == 0) {
        this.$store.commit("showMessage", {
          text:
            "Não Encontramos Ofertas para Você! Entre em contato conosco. Informações no rodapé da página",
          color: "warning",
          show: true,
        });
        this.login = "";
      } else {
        this.coloca(res.data.dados, cpf);

        this.$router.push({ name: "DividasPage" });
      }
    },
    async enviar2() {
      this.$store.commit("zeraMessage");
      // let cpf = this.login.replace(/\D/gi, "");
      let cpf = this.$route.params.cpf2;

      // cpf = "00000048712";

      if (!cpf) {
        this.$store.commit("showMessage", {
          text: "Coloque um CPF ou CNPJ para ver as Ofertas",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }

      let res = await api.get("dividas/" + cpf);

      if (res.data.dados.length == 0) {
        this.$store.commit("showMessage", {
          text:
            "Não Encontramos Ofertas para Você! Entre em contato conosco. Informações no rodapé da página",
          color: "warning",
          show: true,
        });
        this.login = "";
      } else {
        this.coloca(res.data.dados, cpf);

        this.$router.push({ name: "DividasPage" });
      }
    },
  },
};
</script>

<style></style>
